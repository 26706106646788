import React from "react";
import { FaChevronDown } from "react-icons/fa";
import classNames from "classnames";

// interfaces
import { SearchFiltersDropdownButtonProps, ReactValue } from "./SearchFiltersDropdownButton.interface";

// assets
import styles from "@/assets/styles/components/search-filters-field.module.scss";


// TODO: combine with src/components/SearchComponents/SearchFilters/components/SearchFiltersDropdownButton.tsx
function SearchFiltersDropdownButton(props: SearchFiltersDropdownButtonProps) {
  const { label, value, icon, className, error, isOptional, ...rest } = props;

  const searchFiltersDropdownButtonClassName = classNames(
    `${styles["locl-search-filters-field"]} locl-search-filters-field`,
    className,
    {
      [styles["locl-search-filters-field_with-value"]]: !!value,
      [styles["locl-search-filters-field_with-error"]]: !!error,
    }
  );
  const isValueObject = typeof value === "object";

  return (
    <div className={styles["locl-search-filters-field-wrapper"]}>
      <div className={searchFiltersDropdownButtonClassName} { ...rest }>
        <span className={styles["locl-search-filters-field__icon"]}>
          { icon }
        </span>
        <div className={styles["locl-search-filters-field__inner"]} data-testid="locl-search-filters-field-inner">
          <label className={`${styles["locl-search-filters-field__label"]} locl-search-filters-field__label`}>
            { label }
            { isOptional &&
              <span className={styles["locl-search-filters-field__label-optional"]}>(optional)</span>
            }
          </label>
          <span className={styles["locl-search-filters-field__value"]}>
            { !isValueObject ? value : (value as ReactValue)?.text}
          </span>
          {isValueObject && (value as ReactValue)?.additional && (
            <span className={styles["locl-search-filters-field__value__additional"]}>
              { (value as ReactValue)?.additional}
            </span>
          )}
          <span className={styles["locl-search-filters-field__arrow-indicator"]}>
            <FaChevronDown/>
          </span>
        </div>
      </div>
      { error ? <span className={styles["locl-search-filters-field__error"]} data-testid="locl-search-filters-field-error">{ error }</span> : null }
    </div>
  );
}

export default SearchFiltersDropdownButton;
